import React, { useState } from "react"
import update from "immutability-helper"
import {
  Checkbox,
  ChoiceList,
  Spinner,
  LegacyStack,
  Select,
  TextField,
} from "@shopify/polaris"
import getSellingPlans from "../../../../helpers/getSellingPlans"

const SubscriptionOption = (props) => {
  const {
    state,
    setState,
    selectedProduct,
    subscription,
    offerIndex,
    index,
    token,
    shop,
    host,
  } = props
  const [sellingPlansLoading, setSellingPlansLoading] = useState(false)
  const handleSubscriptionChange = async (newChecked, productId) => {
    console.log("newChecked", newChecked)
    setState(
      update(state, {
        product: {
          [offerIndex]: {
            products: {
              [index]: { subscription: { $set: newChecked } },
            },
          },
        },
      })
    )

    if (newChecked) {
      const id = `gid://shopify/Product/${String(productId).replace(
        "gid://shopify/Product/",
        ""
      )}`
      setSellingPlansLoading(true)
      const sellingPlans = await getSellingPlans({ id: id }, token, shop, host)
      setSellingPlansLoading(false)

      if (
        200 === sellingPlans.status &&
        sellingPlans.data.data &&
        sellingPlans.data.data.edges
      ) {
        setState(
          update(state, {
            product: {
              [offerIndex]: {
                products: {
                  [index]: {
                    subscription: {
                      $set: {
                        sellingPlans: sellingPlans.data.data.edges,
                      },
                    },
                  },
                },
              },
            },
          })
        )
      } else {
        setState(
          update(state, {
            product: {
              [offerIndex]: {
                products: {
                  [index]: { subscription: { $set: { sellingPlans: [] } } },
                },
              },
            },
          })
        )
      }
    }
  }

  const handleChooseSellingPlan = (value, index) => {
    setState(
      update(state, {
        product: {
          [offerIndex]: {
            products: {
              [index]: {
                subscription: {
                  $set: {
                    sellingPlans:
                      state.product[offerIndex].products[index].subscription
                        .sellingPlans,
                    choosedSellingPlans: value,
                  },
                },
              },
            },
          },
        },
      })
    )
  }
  return (
    <LegacyStack vertical spacing="tight">
      <Checkbox
        label="Add as a subscription"
        checked={subscription ? true : false}
        onChange={(val) => handleSubscriptionChange(val, selectedProduct.id)}
      />
      {sellingPlansLoading ? (
        <Spinner accessibilityLabel="Loading Selling Plans" size="small" />
      ) : (
        false
      )}
      {subscription &&
      subscription.sellingPlans &&
      Array.isArray(subscription.sellingPlans) &&
      subscription.sellingPlans.length === 0 ? (
        <div className="Polaris-Labelled__HelpText">
          <span>
            There is no selling plan for this product. Please create selling
            plan first.
          </span>
        </div>
      ) : (
        false
      )}
      {subscription &&
      subscription.sellingPlans &&
      Array.isArray(subscription.sellingPlans) &&
      subscription.sellingPlans.length > 0 ? (
        <ChoiceList
          title="Choose a selling plan"
          choices={subscription.sellingPlans.map((item) => {
            return { label: item.node.name, value: item.node.id }
          })}
          selected={
            subscription.choosedSellingPlans
              ? subscription.choosedSellingPlans
              : [subscription.sellingPlans[0].id]
          }
          onChange={(value) => handleChooseSellingPlan(value, index)}
          titleHidden
        />
      ) : (
        false
      )}
    </LegacyStack>
  );
}

export default SubscriptionOption
